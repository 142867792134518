html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
  max-width: 100vw;
}
.appbar-top-margin {
  margin-top: 48px;
}
.banner-top-margin {
  margin-top: 80px;
}
.drawer-height-with-appbar {
  height: calc(100vh - 48px) !important;
}
.drawer-height-with-banner {
  height: calc(100vh - 80px) !important;
}
.map-card {
  height: 100%;
}
@media (min-width: 0px) and (orientation: landscape) {
  .appbar-top-margin {
    margin-top: 48px;
  }
  .banner-top-margin {
    margin-top: 80px;
  }
  .drawer-height-with-appbar {
    height: calc(100vh - 48px) !important;
  }
  .drawer-height-with-banner {
    height: calc(100vh - 80px) !important;
  }
}
@media (min-width: 600px) {
  .appbar-top-margin {
    margin-top: 48px;
  }
  .banner-top-margin {
    margin-top: 80px;
  }
  .drawer-height-with-appbar {
    height: calc(100vh - 48px) !important;
  }
  .drawer-height-with-banner {
    height: calc(100vh - 80px) !important;
  }
}

.scrollbar::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: rgba(230, 230, 230, 0.8);
}

.scrollbar::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: #ef9a11;
}
